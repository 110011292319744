<template>
  <div class="admin">
    <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ error }}
    </div>
    <div v-if="isEdit">
      <b-container class="w-50">
        <b-row class="mt-3">
          <b-col>
            <label class="form-label">Store ID</label>
            <input type="text" class="form-control" v-model="store.sourceStoreId">
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label class="form-label">Store Name</label>
            <input type="text" class="form-control" v-model="store.sourceName">
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label class="form-label">apiKey (PKEnterprise)</label>
            <input type="text" class="form-control" v-model="store.apiKey">
          </b-col>
        </b-row>
        <b-row class="text-center mt-5">
          <b-col cols="6">
            <button class="btn btn-primary" @click="cancel()">Cancel</button>
          </b-col>
          <b-col cols="6">
            <button class="btn btn-success" @click="save()">Submit</button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <b-container class="fluit" >
        <table class="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>Store ID</th>
              <th>Store Name</th>
              <th>apiKey</th>
              <th class="text-center"><b-icon icon="plus-square" variant="success" font-scale="2" @click="add()"></b-icon></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in storeList" :key="o.id" @click="edit(o)">
              <td>{{ o.sourceStoreId }}</td>
              <td>{{ o.sourceName }}</td>
              <td>{{ o.apiKey }}</td>
              <td><b-icon icon="pencil-square" variant="primary" font-scale="2"></b-icon></td>
            </tr>
          </tbody>
        </table>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      token: '',
      user: null,
      storeList: [],
      store: null,
      newStore: {
        sourceStoreId: 0,
        sourceName: '',
        apiKey: '',
        sSource: 'panda',
      },
      error: '',
      isEdit: false,
      pageleave: false,
    };
  },
  mounted() {
    const mytoken = window.localStorage.getItem('token');
    if ((mytoken == undefined) || !mytoken) {
      this.$router.push("/login");
    }
    this.token = mytoken;
    this.user = JSON.parse(window.localStorage.getItem('user'));
    if (this.user.apitype != 'admin') {
      alert("You don't have permission to do this function");
      this.$router.push("/login");
    }
    console.log("Store.vue mounted initData"); //XXXXXXXXXXXXXXXXXXXXX
    this.initData();
  },
  methods: {
    cancel() {
      this.isEdit = false;
    },
    add() {
      this.isEdit = true;
      this.store = JSON.parse(JSON.stringify(this.newStore));
    },
    edit(o) {
      this.isEdit = true;
      this.store = JSON.parse(JSON.stringify(o));
    },
    save() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if ((this.store.id != undefined) && this.store.id) {
        bodyFormData.append("id", this.store.id);
      }
      if (this.store.sourceStoreId) {
        bodyFormData.append("sourceStoreId", this.store.sourceStoreId);
      } else {
        alert("Please input Store ID");
        return;
      }
      if (this.store.apiKey) {
        bodyFormData.append("apiKey", this.store.apiKey);
      } else {
        alert("Please input apiKey");
        return;
      }
      if (this.store.sourceName) {
        bodyFormData.append("sourceName", this.store.sourceName);
      } else {
        alert("Please input Store Name");
        return;
      }
      if (this.store.sSource) {
        bodyFormData.append("sSource", this.store.sSource);
      } else {
        alert("Please Select Source");
        return;
      }
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/storeUpdate",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.isEdit = false;
              if (rt.data.store) {
                that.store = rt.data.store;
                that.getStoreList();
              } else {
                alert("Not data");
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    initData() {
      this.getStoreList();
    },
    getStoreList() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/storeList",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              if (rt.data.storeList) {
                that.storeList = rt.data.storeList;
              } else {
                that.storeList = [];
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
              that.$router.push("/login");
            }
          }
        })
        .catch(function (error) {
          console.log("Store.vue getStoreList error", response); //XXXXXXXXXXXXXXXXXXXXX
          that.error = error;
          that.$router.push("/login");
        });
    },
  }
}
</script>
<style scoped>
.toleft {
  text-align: left;
}
</style>