<template>
  <div class="menu start-0">
    <router-link to="/">Home</router-link> |
    <router-link to="/admin" v-if="isAdmin">Admin | </router-link>
    <router-link to="/store" v-if="isAdmin">Store | </router-link> 
    <router-link to="/login">Login</router-link> 
    <!-- <router-link to="/register">Register | </router-link>  -->
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // isAdmin: false,
    };
  },
  mounted() {
    // let user = JSON.parse(window.localStorage.getItem('user'));
    // console.log("Meun mounted user", user);
    // if (user && (user.apitype == 'admin')) {
    //   this.isAdmin = true;
    // }
    // console.log("Meun mounted isAdmin", this.isAdmin);
  },
  // beforeUpdate() {
  //   let user = JSON.parse(window.localStorage.getItem('user'));
  //   console.log("Meun beforeUpdate user", user);
  //   if (user && (user.apitype == 'admin')) {
  //     this.isAdmin = true;
  //   } else {
  //     this.isAdmin = false;
  //   }
  //   console.log("Meun beforeUpdate isAdmin", this.isAdmin);
  // },
};
</script>
<style scoped>
.menu {
  text-align: left;
}
</style>