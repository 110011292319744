<template>
  <div class="login">
    <b-container class="w-50">
      <b-row class="text-center mt-6">
        <b-col>
          <h2 class="text-uppercase text-center mb-5 text-dark">
            Welcome
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div
            class="alert alert-danger d-flex align-items-center"
            role="alert"
            v-if="error"
          >
            <i class="fas fa-info-circle"></i>
            <div class="ms-2">{{ errorCode }}</div>
          </div>
          <b-form-group
            id="input-group-1"
            label="Username"
            label-for="input-1"
          >
            <b-form-input
              id="input-1"
              v-model="username"
              type="text"
              placeholder="Enter username"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="input-group-2"
            label="Password"
            label-for="input-2"
          >
            <b-form-input
              id="input-2"
              v-model="password"
              type="password"
              placeholder="Enter Password"
              required
            ></b-form-input>
          </b-form-group>

          <div class="w-100 text-center">
            <b-button type="submit" variant="success" @click.prevent="doLogin">Login</b-button>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
      error: false,
      errorCode: "",
    };
  },
  mounted() {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('user');
  },
  methods: {
    doLogin() {
      console.log("Login.vue doLogin start ", this.username, this.password); //XXXXXXXXXXXXXXXXXXX
      var bodyFormData = new FormData();
      bodyFormData.append("username", this.username);
      bodyFormData.append("password", this.password);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/login",
        data: bodyFormData,
      })
        .then(response => {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              if (rt.data.token) {
                window.localStorage.setItem('token', rt.data.token);
                window.localStorage.setItem('user', JSON.stringify(rt.data.user));
                this.$emit('userlogin', JSON.stringify(rt.data.user));
                that.$router.push("/");
              }
            } else if (rt.message != undefined) {
              that.error = true;
              that.errorCode = rt.message;
            }
          }
        })
        .catch(error => {
          that.error = true;
          that.errorCode = error;
        });
    },
  },
};
</script>