<template>
  <div id="app">
    <div id="nav">
      <Menu :isAdmin="isAdmin"/>
    </div>
    <router-view @userlogin="getUser"></router-view>
  </div>
</template>
<script>
import Menu from "@/components/Menu.vue";
export default {
  components: {
    Menu,
  },
  data() {
    return {
      isAdmin: "",
    };
  },
  computed: {
  },
  mounted() {
    // this.checkAuth();
  },
  methods: {
    getUser(userdata) {
      // console.log("App.vue getUser",userdata);
      let user = JSON.parse(userdata);
      if (user && (user.apitype != undefined) && (user.apitype == 'admin')) {
        this.isAdmin = true;
      } else {
        this.isAdmin = false;
      }
    }
    // checkAuth() {
    //   var that = this;
    //   this.DoAjaxCall({ method: "auth" }, function (response) {
    //     if (response.result == true) {
    //       console.log(response);
    //       response.authObj.isAuth = true;
    //     } else {
    //       that.$router.push("/");
    //     }
    //   });
    // },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
