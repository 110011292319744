<template>
  <div class="home">
    <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ error }}
    </div>
    <div v-if="showinfo" class="border border-dark text-center m-1 p-1">
      <div>
        <button type="button" class="btn btn-secondary btn-sm" @click="closeInfo()">Close</button>
      </div>
      <div class="toleft m-1">
        <pre>{{ showinfo }}</pre>
      </div>
      <div>
        <button type="button" class="btn btn-secondary btn-sm" @click="closeInfo()">Close</button>
      </div>
    </div>
    <div v-if="orders.length>0" class="container-fluid">
      <b-row class="font-weight-bold p-3">
        <b-col cols="3">
          Order No.
        </b-col>
        <b-col cols="3">
          Update Time
        </b-col>
        <b-col cols="3">
          Status
        </b-col>
        <b-col cols="3">
          <b-button  variant="primary" size="sm" @click="getOrders()">Reload</b-button>
        </b-col>
      </b-row>
      <b-row v-for="o in orders" :key="o.id">
        <b-col cols="3">
          {{ o.order_no }}
        </b-col>
        <b-col cols="3">
          {{ o.order_tm }}
        </b-col>
        <b-col cols="3">
          {{ o.order_status }}
        </b-col>
        <b-col cols="3">
          <button type="submit" class="btn btn-outline-primary btn-sm" @click="getOrderInfo(o)">Info</button>&nbsp;
          <button type="submit" class="btn btn-outline-success btn-sm" @click="acceptOrder(o)">Accept</button>&nbsp;
          <button type="submit" class="btn btn-outline-danger btn-sm" @click="DeniedOrder(o)">Denied</button>
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <p>No Data <b-button  variant="primary" size="sm" @click="getOrders()">Get Data</b-button></p> 
      {{ orders }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      token: '',
      timerget: false,
      user: null,
      orders: [],
      error: '',
      showinfo: '',
      pageleave: false,
    };
  },
  mounted() {
    const mytoken = window.localStorage.getItem('token');
    if ((mytoken == undefined) || !mytoken) {
      this.$router.push("/login");
    }
    this.user = JSON.parse(window.localStorage.getItem('user'));
    this.token = mytoken;
    this.initData();
  },
  watch: {
    // current_page: function () {
    //   this.get_product_list(1);
    // },
  },
  computed: {
    // current_page: function (newval, oldval) {
    //   return false;
    // },
  },
  methods: {
    closeInfo() {
      this.getOrders();
      this.showinfo = '';
    },
    getOrderInfo(o) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("store", 'panda');
      bodyFormData.append("order_no", o.order_no);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/panda/getInfo",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            console.log("Home.vue getOrderInfo", rt); //XXXXXXXXXXXX
            if (rt.code == 0) {
              if (rt.data.order) {
                that.showinfo = rt.data.order;
              } else {
                alert("Not data"); //XXXXXXXXXXXX
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    DeniedOrder(o) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("store", 'panda');
      bodyFormData.append("order_no", o.order_no);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/panda/deniedOrder",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            console.log("Home.vue DeniedOrder", rt); //XXXXXXXXXXXX
            if (rt.code == 0) {
              if (rt.data.orders) {
                that.showinfo = rt.data.txt_message;
              } else {
                alert("Not data return"); //XXXXXXXXXXXX
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    acceptOrder(o) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("store", 'panda');
      bodyFormData.append("order_no", o.order_no);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/panda/acceptOrder",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            console.log("Home.vue acceptOrder", rt); //XXXXXXXXXXXX
            if (rt.code == 0) {
              if (rt.data.txt_message) {
                that.showinfo = rt.data.txt_message;
              } else {
                alert("Not data return"); //XXXXXXXXXXXX
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    beforeRouteEnter(to, from, next) {
      this.pageleave = false;
      console.log("Home.vue beforeRouteEnter", to, from, next); //XXXXXXXXXXXX
      next();
    },
    beforeRouteLeave(to, from, next) {
      console.log("Home.vue beforeRouteLeave", to, from, next); //XXXXXXXXXXXX
      this.pageleave = true;
      next();
    },
    initData() {
      if (this.user && (this.user.apitype == 'admin')) {
        this.$router.push("/admin");
        return;
      }
      this.getOrders();
    },
    getOrders() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("store", 'panda');
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/getOrders",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            console.log("Home.vue getOrders", rt); //XXXXXXXXXXX
            if (rt.code == 0) {
              if (rt.data.orders) {
                that.orders = rt.data.orders;
              } else {
                that.orders = [];
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
              if (that.timerget) {
                clearTimeout(that.timerget);
                that.timerget = false;
              }

              that.$router.push("/login");
            }
          }
        })
        .catch(function (error) {
          that.error = error;
          if (that.timerget) {
            clearTimeout(that.timerget);
            that.timerget = false;
          }
          that.$router.push("/login");
        });
    },
  }
}
</script>
<style scoped>
.toleft {
  text-align: left;
}
pre { 
   white-space: pre-wrap; 
   word-break: break-word;
}
</style>